import { cssBundleHref } from '@remix-run/css-bundle';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useNavigate,
  useRouteError } from
'@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';

// import components
import { Toaster } from '@repo/ui-shadcn/components/ui/toast/toaster';

// import types
import type { LinksFunction } from '@remix-run/node';

// import assets
import { FlameKindling } from 'lucide-react';
import styles from './styles/globals.css';
import { Button, Separator } from '@repo/ui-shadcn';

// define remix links function
export const links: LinksFunction = () => [
{ rel: 'stylesheet', href: styles },
{ rel: 'icon', href: '/favicon.ico' },
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : [])];

// define remix loader function
export async function loader() {
  return json({
    ENV: {
      SENTRY_ENVIRONMENT: process?.env?.SENTRY_ENVIRONMENT || '',
      SENTRY_DSN: process?.env?.SENTRY_DSN || '',
      ZENDESK_KEY: process?.env?.ZENDESK_KEY || ''
    }
  });
}

// define remix error boundary function
export function ErrorBoundary() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = (useRouteError() as any);
  const navigate = useNavigate();

  // capture error with sentry
  captureRemixErrorBoundaryError(error);
  console.error(error);

  return (
    <html lang="en">
      <head>
        <title>{error?.status ? `Error ${error?.status} | Client Portal` : 'Client Portal'}</title>
        <Meta />
        <Links />
      </head>

      <body>
        <div className="flex h-screen items-center justify-center text-center">
          <div className="flex flex-row items-center">
            {error?.status &&
            <div className="me-5 flex h-16 items-center space-x-4 text-sm">
                <div className="text-2xl">{error?.status}</div>

                <Separator orientation="vertical" />
              </div>}


            <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
              <FlameKindling size={96} className="text-muted-foreground" />
              <h3 className="mt-4 text-lg font-semibold">{`Oops! It's not you, it's us.`}</h3>
              <p className="text-muted-foreground mb-4 mt-2 text-sm">
                {`We're working to fix the issue. Please check back soon. Thanks for your understanding.`}
              </p>
              <Button variant={'outline'} className="ms-3" size="sm" onClick={() => navigate(-1)}>
                Back to where you were
              </Button>
            </div>
          </div>
        </div>
        <Scripts />
      </body>
    </html>);

}

export const shouldRevalidate = () => false;

function App() {
  const loaderData = useLoaderData<typeof loader>();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="oms, order management system, supply chains, connects, integrated" />
        <meta name="author" content="Merchant" />

        <Meta />
        <Links />
      </head>

      <body suppressHydrationWarning={true}>
        <Outlet />
        <Toaster />

        <ScrollRestoration />

        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(loaderData.ENV)}`
          }}>
        </script>
        <Scripts />

        <LiveReload port={3002} />
      </body>
    </html>);

}

export default withSentry(App, {
  errorBoundaryOptions: {
    fallback: ErrorBoundary
  }
});